<template>
  <div class="swiper-slide">
    <!-- <img src="~assets/image/main/@top.png" alt="" /> -->
    <img :src="imgPath" alt="" />
    <span class="status">{{ `추천공고${index + 1}` }}</span>
    <p class="t1">{{ banner.companyNm }} <br />{{ banner.jobPostTitle }}</p>
    <button
      class="link __detail"
      @click="moveToPage(`/job-detail/${banner.documentNo}`)"
    >
      상세보기
    </button>
  </div>
</template>

<script>
import { URL } from 'Api/api'

export default {
  props: {
    detailBtn: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
    },
    banner: {
      type: Object,
    },
  },
  computed: {
    imgPath() {
      if (this.banner.photo)
        return `${URL}/api/hgc/file/view/${this.banner.photo}`
      else return require('assets/image/main/@top.png')
    },
  },
}
</script>

<style scoped>
.__detail {
  background: transparent;
}
</style>
