<template>
  <div
    class="swiper-slide"
    @click=";[onClick, moveToPage(`/job-detail/${banner.documentNo}`)]"
  >
    <img :src="imgPath" alt="" />
    <div class="txt">
      <p class="t1">{{ banner.companyNm }}</p>
      <p class="t2">{{ banner.jobPostTitle }}</p>
    </div>
  </div>
</template>

<script>
import { URL } from 'Api/api'

export default {
  props: {
    index: {
      type: Number,
    },
    banner: {
      type: Object,
    },
  },
  methods: {
    onClick() {
      this.$emit('slideClick')
    },
  },
  computed: {
    imgPath() {
      if (this.banner.photo)
        return `${URL}/api/hgc/file/view/${this.banner.photo}`
      else return require('assets/image/etc/etc-hotel-noimg.svg')
    },
  },
}
</script>

<style></style>
