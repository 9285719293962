<template>
  <li
    class="special-job-item"
    @click="moveToPage(`/job-detail/${item.documentNo}`)"
  >
    <img :src="imgPath" alt="" class="logo" />
    <p class="t0">
      {{ splitToArry(item.workingAddress) }}
      <span v-if="item.distance">{{
        `${distanceUnitBrackets(item.distance)}`
      }}</span>
    </p>
    <p class="t1">
      {{ item.companyNm }}
    </p>
    <i
      v-if="item.pushStatus"
      class="status"
      :class="{ disabled: !isNegoAvailable }"
      @click="callNego"
      >{{ pushStatus }}
    </i>
    <p class="t2">
      {{ item.jobPostTitle }}
    </p>
    <p class="t2" v-if="!isApp">
      {{ `(${item.positionName}: ${insertCommaToNumber(item.payAmt)}/1일)` }}
    </p>
    <p class="t2" v-else>
      {{ `${item.positionName}` }}<br />
      {{ `(${insertCommaToNumber(item.payAmt)}/1일)` }}
    </p>
    <!-- 2022년 4월 4일 임시 주석처리 -->
    <!-- <span class="timer" v-if="item.recruitingStatus == 'RECRUITING'">
      D-{{ item.closingTime }}
    </span>
    <span class="timer" v-else>모집마감</span> -->
  </li>
</template>

<script>
import { URL } from 'Api/api'
import { PUSH_STATUS, PUSH_STATUS_NAME } from 'Configs/pushStatus'
import { postApplyJob } from 'Api/modules'

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    //{documentNo, jobPostTitle, closingTime, positionName, positionCnt, payAmt, workingAddress, workingTerm, emphasisCodes, recruitingStatus, photo, companyNm, closingDt, pushStatus}
    //pushstaus : `${jobRequestId}-${pushStatus}`
  },
  data() {
    return {
      updatedPushStatus: null,
      isNegoUpdated: false,
    }
  },
  computed: {
    imgPath() {
      if (this.item.photo) return `${URL}/api/hgc/file/view/${this.item.photo}`
      else return require('assets/image/etc/etc-hotel-noimg.svg')
    },
    // time() {
    //   const close = this.item.closingDt //+ ' ' + this.item.closingTime
    //   return this.diffCalendar(new Date(), close)
    // },
    pushStatus() {
      if (this.item.pushStatus) {
        //pushStatus 의도한 값이 아닌 경우 예외처리
        const pushStatusSplit = this.item.pushStatus.split('-')
        if (pushStatusSplit.length < 2) return ''

        const pushStatus = pushStatusSplit[1]

        if (this.item.recruitingStatus === 'CLOSE') {
          return PUSH_STATUS_NAME[pushStatus]
        } else {
          //근무요청 상태와 근무확정 상태에서는 그 다음 상태를 버튼으로 활성화 한다.
          if (pushStatus === 'SCALL' || pushStatus === 'SOK') {
            const targetIndex = PUSH_STATUS.findIndex(
              status => status.value === pushStatus,
            )
            //그 다음 상태를 표시
            const requestPushStatus = PUSH_STATUS[targetIndex + 1].name
            return requestPushStatus
          }
          //근무응답, 합격확인 상태는 그 상태 그대로 표시하면 된다. 버튼 역할하지 않는다
          else {
            return PUSH_STATUS_NAME[pushStatus]
          }
        }
      }
      return ''
    },

    isNegoAvailable() {
      if (this.isNegoUpdated) return false
      if (!this.item.pushStatus) return false
      if (this.item.recruitingStatus === 'CLOSE') return false

      //pushStatus 의도한 값이 아닌 경우 예외처리
      const pushStatusSplit = this.item.pushStatus.split('-')
      if (pushStatusSplit.length < 2) return false

      const pushStatus = pushStatusSplit[1]

      //근무응답, 합격확인의 경우에만 버튼으로 활성화
      if (pushStatus && (pushStatus === 'SCALL' || pushStatus === 'SOK'))
        return true
      return false
    },
  },
  methods: {
    // diffCalendar(stDt, endDt) {
    //   const startDate = new Date(stDt)
    //   const endDate = new Date(endDt)
    //   const result = endDate.getTime() - startDate.getTime()
    //   return result / (1000 * 60 * 60 * 24) //일기준
    // },
    async callNego(event) {
      event.stopPropagation()
      if (!this.isNegoAvailable) return
      if (!this.item.pushStatus) return

      //pushStatus 의도한 값이 아닌 경우 예외처리
      const pushStatusSplit = this.item.pushStatus.split('-')
      if (pushStatusSplit.length < 2) throw new Error('pushStatus invalid')

      const jobRequestId = pushStatusSplit[0]
      const senderId = this.account.userId
      const targetStatus = PUSH_STATUS.find(
        status => status.name === this.pushStatus,
      )
      const pushStatus = targetStatus.value

      try {
        await postApplyJob({ jobRequestId, senderId, pushStatus })
        this.isNegoUpdated = true //버튼비활성화
        this.$toasted.show(`${this.pushStatus} 하셨습니다.`)
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.special-job-item {
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    box-shadow: 4px 7px 6px 0 rgb(0, 0, 0 / 10%);
    border: solid 1px #ff6831;
  }

  img.logo {
    border-radius: 50%;
  }

  p.t2 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
main.sub-main .jobs-wrap .jobs-list li .status {
  margin-top: 20px !important;
}
main.sub-main .jobs-wrap .jobs-list li .status,
main.main .jobs-wrap .jobs-list li .status {
  cursor: pointer;
  pointer-events: all;
  &.disabled {
    cursor: none;
    pointer-events: none;
    color: #495052;
    &:hover {
      background-color: none;
    }
  }
  &:hover {
    background-color: rgba(#ff6831, 0.1);
  }
}
@media (max-width: 1024px) {
  main.main .jobs-wrap .jobs-list li .status,
  main.sub-main .jobs-wrap .jobs-list li .status {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 12vw;
    height: 4vw;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 2.4vw;
    border: solid 1px #ddd;
    background-color: #fff;
    font-size: 2.8vw;
    font-weight: 500;
    letter-spacing: -0.08vw;
    color: #f74f11;
    font-style: normal;
    line-height: 1;
    float: right;
    margin-top: vw(16);
    // position: absolute;
    // right: 2.66667vw;
    // bottom: 15.66667vw;
  }
}

@media (min-width: 1024px) {
  main.main .jobs-wrap .jobs-list li .status,
  main.sub-main .jobs-wrap .jobs-list li .status {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 60px;
    height: 25px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 12.5px;
    border: solid 1px #ddd;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: #f74f11;
    font-style: normal;
    line-height: 1;
    // margin: 10px 30px 0 0;
    margin-left: 10px;
    margin-top: 30px;
    float: right;
    position: static;
  }
}
//2022.07.13거리 관련 레이아웃 변경으로 인한 주석
// .distance {
//   background: center / cover no-repeat url('~assets/image/ico/location-pin.svg');
// }
</style>
