<template>
  <div class="_layer" :class="{ on }">
    <p class="t1">스페셜 채용 영역 상품</p>
    <dl>
      <dt>스페셜</dt>
      <dd>300,000원/주</dd>
    </dl>
    <button class="btn _closed" @click="close">
      <i class="el-icon-close"></i>
    </button>
  </div>
</template>

<script>
import popupMixin from 'Mixins/popup'

export default {
  mixins: [popupMixin],
}
</script>

<style></style>
