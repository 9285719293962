<template>
  <section class="main-top">
    <div class="img-slides swiper-container" id="imgSlides">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <router-link to="/Jeju2Detail">
            <img
              class="jejuImg"
              src="~assets/image/main/summerJejuBanner.png"
              alt="제주살이"
              v-if="detailBtn"
            />
          </router-link>
        </div>
        <div class="swiper-slide">
          <router-link to="/JobAppli">
            <img
              class="jejuImg"
              src="~assets/image/main/customizedJob_1.png"
              alt="맞춤일자리신청"
              v-if="detailBtn"
            />
          </router-link>
        </div>
        <!--임의로 배너 추가하는 부분-->
        <!-- <div class="swiper-slide">
          <router-link to="/jejuDetail">D
            <img
              class="jejuImg"
              src="~assets/image/main/jejuBanner_2.png"
              alt="제주 한달 살기"
              v-if="detailBtn"
            />
            <img
              class="jejuImg"
              src="~assets/image/main/find_jejuBanner_2.png"
              alt="제주 한달 살기"
              v-if="!detailBtn"
            />
            <span
              class="status jejuStatus"
              v-if="detailBtn"
              style="color: #01a4d9"
              >EVENT!</span
            >
            <p class="t1" style="color: #01a4d9">
              여행도 하고 돈도 벌고!<br />제주 한달 살기 이벤트 참가자 모집!
            </p>
            <button
              class="link __detail jejuBtn"
              v-if="detailBtn"
              style="
                background-color: transparent;
                border: 1px solid #f5f5f5;
                color: #f5f5f5;
              "
            >
              상세보기
            </button>
          </router-link>
        </div> -->
        <swiper-main-item
          v-for="(bannerItem, idx) in list"
          :key="'mi' + bannerItem.documentNo"
          :banner="bannerItem"
          :index="idx"
          :detailBtn="detailBtn"
        ></swiper-main-item>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-option">
        <div class="swiper-pagination swiper-pagination-fraction"></div>
        <button
          :style="{ display: isStop ? 'inline-block' : 'none' }"
          class="start"
          @click="startClick"
        >
          <i class="el-icon-video-play"></i>
        </button>
        <button
          :style="{ display: !isStop ? 'inline-block' : 'none' }"
          class="stop"
          @click="stopClick"
        >
          <i class="el-icon-video-pause"></i>
        </button>
      </div>
    </div>
    <div v-if="sideSlides" class="txt-slides swiper-container" id="txtSlides">
      <div class="swiper-wrapper">
        <div class="swiper-slide" @click="routePush('jejuAllTheTime')">
          <img
            style="width: auto; border: none"
            src="~assets/image/main/summerJeju.png"
            alt=""
          />
          <div class="txt">
            <p class="t1">맞춤 일자리 신청</p>
            <p class="t2">신청만하면 나도 출근한다!</p>
          </div>
        </div>
        <div class="swiper-slide" @click="routePush('JobAppli')">
          <img
            style="width: auto; border: none"
            src="~assets/image/main/customizedTxt.png"
            alt=""
          />
          <div class="txt">
            <p class="t1">떠나자 제주로!</p>
            <p class="t2">숙식제공 받고! 알바하고! 알바비 받고!</p>
          </div>
        </div>
        <swiper-side-item
          v-for="(bannerItem, idx) in list"
          :key="'si' + bannerItem.documentNo"
          :banner="bannerItem"
          :index="idx"
          @slideClick="slideClick"
        ></swiper-side-item>
      </div>
    </div>
  </section>
</template>

<script>
import SwiperMainItem from '../partials/SwiperMainItem'
import SwiperSideItem from '../partials/SwiperSideItem'

import Swiper from 'swiper'
import 'swiper/css/swiper.css'

export default {
  components: {
    SwiperMainItem,
    SwiperSideItem,
  },
  props: {
    sideSlides: {
      type: Boolean,
      default: true,
    },
    detailBtn: {
      type: Boolean,
      default: true,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isStop: false,
    }
  },
  watch: {
    list(list) {
      if (list) {
        this.initSwiper()
      }
    },
  },
  methods: {
    slideClick() {
      this.imgSlide.autoplay.start()
      this.txtSlide.autoplay.start()
    },
    startClick() {
      this.isStop = false
      this.imgSlide.autoplay.start()
      this.txtSlide.autoplay.start()
    },
    stopClick() {
      this.isStop = true
      this.imgSlide.autoplay.stop()
      this.txtSlide.autoplay.stop()
    },

    initSwiper() {
      this.$nextTick(() => {
        if (this.sideSlides) {
          this.txtSlide = new Swiper('#txtSlides', {
            slidesPerView: 4,
            // loopedSlides: 4,
            speed: 500, //원래 없음 2022.05.18 메인 스와이프와 시간 안맞아서 추가
            //원복
            // autoplay: {
            //   delay: 3000,
            //   disableOnInteraction: true,
            // },
            autoplay: false,
            direction: 'vertical',
            autoHeight: true,
            allowTouchMove: false,
            watchSlidesProgress: true,
            preventClicks: false,
            preventClicksPropagation: false,
          })
        }
        this.imgSlide = new Swiper('#imgSlides', {
          // loopSlides: 4,
          effect: 'fade',
          speed: 500,
          loop: true,
          autoplay: {
            delay: 3000,
            disableOnInteraction: true,
          },
          pagination: {
            el: '.swiper-option .swiper-pagination',
            clickable: true,
            type: 'fraction',
            renderBullet: function (index, className) {
              return (
                '<span class="' + className + '">' + (index + 1) + '</span>'
              )
            },
          },
          thumbs: {
            swiper: this.txtSlide,
          },
          preventClicks: false,
          preventClicksPropagation: false,
        })
      })
    },
    routerPush() {
      this.$router.push('/Promotion')
    },
  },
  async created() {},
}
</script>
