<template>
  <section class="jobs-wrap">
    <h2>{{ title }}</h2>
    <!--button
      v-if="showProductInfo"
      class="btn _product"
      @click="toggleProductInfo"
    >
      상품안내 <i class="el-icon-arrow-right"></i>
    </button-->
    <product-info
      v-if="showProductInfo"
      :visible.sync="productInfovisible"
    ></product-info>
    <ul class="jobs-list">
      <special-job-item
        v-for="item in list"
        :item="item"
        :key="item.documentNo"
      ></special-job-item>
    </ul>
  </section>
</template>

<script>
import SpecialJobItem from '../partials/SpecialJobItem'
import ProductInfo from '../popup/ProductInfo'

export default {
  components: {
    SpecialJobItem,
    ProductInfo,
  },
  props: {
    title: {
      type: String,
      defautl: '',
    },
    showProductInfo: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      productInfovisible: false,
    }
  },
  methods: {
    toggleProductInfo() {
      this.productInfovisible = !this.productInfovisible
    },
  },
  beforeDestroy() {
    this.productInfovisible = false
  },
}
</script>

<style></style>
